/* ======================================================================== */
/* 15. PJAX Update Language Switcher */
/* ======================================================================== */
function PJAXUpdateLanguageSwitcher(data) {

	return new Promise(function (resolve, reject) {

		var $currentSwitcher = $('.lang-switcher');

		if (!$currentSwitcher.length) {
			resolve(true);
			return;
		}

		var
			rawHTML = $.parseHTML(data.next.html),
			$newSwitcher = $(rawHTML).find('.lang-switcher');

		$currentSwitcher.replaceWith($newSwitcher);

		// eval language switcher inline scripts
		$newSwitcher.find('script').each(function () {
			try {
				window.eval(this.text);
			} catch (error) {
				console.warn(error);
			}
		});

		resolve(true);

	});

}
